/*
 * action types
 */

// error

export const GENERIC_ERROR = 'GENERIC_ERROR';

// locale
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

// auth
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGING_OUT = 'LOGGING_OUT';
export const SIGNING_UP = 'SIGNING_UP';
export const SIGNED_UP = 'SIGNED_UP';
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

// manuscripts
export const REQUEST_MANUSCRIPTS = 'REQUEST_MANUSCRIPTS';
export const RECEIVE_MANUSCRIPTS = 'RECEIVE_MANUSCRIPTS';
export const REQUEST_SINGLE_MANUSCRIPT = 'REQUEST_SINGLE_MANUSCRIPT';
export const RECEIVE_SINGLE_MANUSCRIPT = 'RECEIVE_SINGLE_MANUSCRIPT';
export const CLEAN_UP_MANUSCRIPTS = 'CLEAN_UP_MANUSCRIPTS';
export const ADDING_MANUSCRIPT = 'ADDING_MANUSCRIPT';
export const MANUSCRIPT_ADDED_SUCCESS = 'MANUSCRIPT_ADDED_SUCCESS';
export const MANUSCRIPT_ADDED_ERROR = 'MANUSCRIPT_ADDED_ERROR';
export const DELETE_MANUSCRIPT_SUCCESS = 'DELETE_MANUSCRIPT_SUCCESS';
export const DELETING_MANUSCRIPT = 'DELETING_MANUSCRIPT';
export const CLEAN_UP_MANUSCRIPT = 'CLEAN_UP_MANUSCRIPT';

// authors
export const REQUEST_AUTHORS = 'REQUEST_AUTHORS';
export const RECEIVE_AUTHORS = 'RECEIVE_AUTHORS';
export const REQUEST_SINGLE_AUTHOR = 'REQUEST_SINGLE_AUTHOR';
export const RECEIVE_SINGLE_AUTHOR = 'RECEIVE_SINGLE_AUTHOR';
export const CLEAN_UP_AUTHORS = 'CLEAN_UP_AUTHORS';
export const ADDING_AUTHOR = 'ADDING_AUTHOR';
export const AUTHOR_ADDED_SUCCESS = 'AUTHOR_ADDED_SUCCESS';
export const AUTHOR_ADDED_ERROR = 'AUTHOR_ADDED_ERROR';
export const DELETE_AUTHOR_SUCCESS = 'DELETE_AUTHOR_SUCCESS';
export const DELETING_AUTHOR = 'DELETING_AUTHOR';
export const CLEAN_UP_AUTHOR = 'CLEAN_UP_AUTHOR';

// nisba
export const REQUEST_NISBAS = 'REQUEST_NISBAS';
export const RECEIVE_NISBAS = 'RECEIVE_NISBAS';
export const REQUEST_SINGLE_NISBA = 'REQUEST_SINGLE_NISBA';
export const RECEIVE_SINGLE_NISBA = 'RECEIVE_SINGLE_NISBA';
export const CLEAN_UP_NISBAS = 'CLEAN_UP_NISBAS';
export const ADDING_NISBA = 'ADDING_NISBA';
export const NISBA_ADDED_SUCCESS = 'NISBA_ADDED_SUCCESS';
export const NISBA_ADDED_ERROR = 'NISBA_ADDED_ERROR';
export const DELETE_NISBA_SUCCESS = 'DELETE_NISBA_SUCCESS';
export const DELETING_NISBA = 'DELETING_NISBA';
export const CLEAN_UP_NISBA = 'CLEAN_UP_NISBA';

// group
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const REQUEST_SINGLE_GROUP = 'REQUEST_SINGLE_GROUP';
export const RECEIVE_SINGLE_GROUP = 'RECEIVE_SINGLE_GROUP';
export const CLEAN_UP_GROUPS = 'CLEAN_UP_GROUPS';
export const DELETE_SINGLE_GROUP = 'DELETE_SINGLE_GROUP';

// subjects
export const REQUEST_SUBJECTS = 'REQUEST_SUBJECTS';
export const RECEIVE_SUBJECTS = 'RECEIVE_SUBJECTS';
export const REQUEST_SINGLE_SUBJECT = 'REQUEST_SINGLE_SUBJECT';
export const RECEIVE_SINGLE_SUBJECT = 'RECEIVE_SINGLE_SUBJECT';
export const CLEAN_UP_SUBJECTS = 'CLEAN_UP_SUBJECTS';
export const RECEIVE_NORMALIZED_SUBJECTS = 'RECEIVE_NORMALIZED_SUBJECTS';
export const ADDING_SUBJECT = 'ADDING_SUBJECT';
export const SUBJECT_ADDED_SUCCESS = 'SUBJECT_ADDED_SUCCESS';
export const SUBJECT_ADDED_ERROR = 'SUBJECT_ADDED_ERROR';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETING_SUBJECT = 'DELETING_SUBJECT';
export const CLEAN_UP_SUBJECT = 'CLEAN_UP_SUBJECT';

// logins
export const REQUEST_LOGINS = 'REQUEST_LOGINS';
export const RECEIVE_LOGINS = 'RECEIVE_LOGINS';
export const CLEAN_UP_LOGINS = 'CLEAN_UP_LOGINS';

// users
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const REQUEST_SINGLE_USER = 'REQUEST_SINGLE_USER';
export const RECEIVE_SINGLE_USER = 'RECEIVE_SINGLE_USER';
export const CLEAN_UP_USERS = 'CLEAN_UP_USERS';

// cross-references

export const REQUEST_CROSS_REFERENCES = 'REQUEST_CROSS_REFERENCES';
export const RECEIVE_CROSS_REFERENCES = 'RECEIVE_CROSS_REFERENCES';
export const DELETE_CROSS_REFERENCE = 'DELETE_CROSS_REFERENCE';
export const UPDATE_CROSS_REFERENCE = 'DELETE_CROSS_REFERENCE';
export const RECEIVE_CROSS_REFERENCE_SEE_ALSO =
    'RECEIVE_CROSS_REFERENCE_SEE_ALSO';
export const REQUEST_CROSS_REFERENCE_SEE_ALSO =
    'REQUEST_CROSS_REFERENCE_SEE_ALSO';
export const CROSS_REFERENCE_ERROR = 'CROSS_REFERENCE_ERROR';
export const CLEAR_CROSS_REFERENCE_FORM = 'CLEAR_CROSS_REFERENCE_FORM';
export const CREATED_CROSS_REFERENCE = 'CREATED_CROSS_REFERENCE';
export const CLEAR_CROSS_REFERENCES = 'CLEAR_CROSS_REFERENCES';
